var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[(_vm.isFromDocument ? '' : _vm.data.properties.filed_content != 'Hide')?_c('div',{style:(_vm.getElementStyle)},[(
          _vm.isFromDocument
            ? ''
            : !_vm.data.properties.hideLabel &&
              _vm.data.properties.filed_content != 'Hide'
        )?_c('div',{class:{ 'highlight-border': _vm.highlight }},[_vm._v(" "+_vm._s(_vm.label || _vm.data.label)+" "),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1):_vm._e(),(!_vm.isList && _vm.data.properties.filed_content != 'Hide')?_c('div',[(_vm.form[_vm.data.key])?_c('img',{class:{ 'focus-border-input': _vm.highlight },style:(_vm.getStyle),attrs:{"src":_vm.form[_vm.data.key],"alt":"IMG"}}):(_vm.getLogoUrl)?_c('img',{class:{ 'focus-border-input': _vm.highlight },style:(_vm.getStyle),attrs:{"src":_vm.getLogoUrl,"alt":"IMG"}}):_vm._e(),(_vm.isView)?_c('div',[(!_vm.form[_vm.data.key] && !_vm.getLogoUrl)?_c('p',[_vm._v("-")]):_vm._e()]):_c('div',[(
              _vm.isFromDocument
                ? this.filledByFromDocument[0] === 'RECEIVER'
                : _vm.data.filled_by === 'RECEIVER' &&
                  !this.imgData &&
                  !_vm.form[_vm.data.key] &&
                  !_vm.getLogoUrl
            )?_c('img',{style:(_vm.getStyle),attrs:{"src":require("../../../../src/assets/img/default_img.jpg"),"alt":"IMG","disabled":_vm.isDisable}}):_vm._e(),(
              (_vm.isFromDocument
                ? this.filledByFromDocument[0] === 'RECEIVER'
                : _vm.data.filled_by === 'RECEIVER') && !_vm.fromEntityViews && _vm.data.input_type == 'IMAGE'
            )?_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){_vm.outerVisible = true}}},[_vm._v("Upload")]):_vm._e(),(_vm.data.input_type =='GIF' && _vm.data.filled_by === 'RECEIVER' && !_vm.fromEntityViews )?_c('el-upload',{staticClass:"d-flex",attrs:{"on-change":_vm.uploadGifFile,"action":"","show-file-list":false,"auto-upload":false,"accept":".png, .jpg, .jpeg, .gif"}},[(_vm.data.filled_by === 'RECEIVER' && !_vm.fromEntityViews  && _vm.data.input_type == 'GIF')?_c('el-button',{attrs:{"size":"small","type":"primary","disabled":_vm.isFromDocument ? false : _vm.checkReadonly() || _vm.readonly()}},[_vm._v(" Upload ")]):_vm._e()],1):_vm._e(),(
              _vm.data.filled_by === 'SENDER_OR_RECEIVER' &&
              !this.imgData &&
              !_vm.form[_vm.data.key] &&
              !_vm.getLogoUrl 
            )?_c('img',{style:(_vm.getStyle),attrs:{"src":require("../../../../src/assets/img/default_img.jpg"),"alt":"IMG","disabled":_vm.isFromDocument ? false : _vm.checkReadonly() || _vm.readonly()}}):_vm._e(),(_vm.data.filled_by === 'SENDER_OR_RECEIVER' && !_vm.fromEntityViews  && _vm.data.input_type == 'IMAGE')?_c('el-button',{attrs:{"size":"small","type":"primary","disabled":_vm.isFromDocument ? false : _vm.checkReadonly() || _vm.readonly()},on:{"click":function($event){_vm.outerVisible = true}}},[_vm._v(" Upload ")]):_vm._e(),(_vm.data.input_type =='GIF' && _vm.data.filled_by === 'SENDER_OR_RECEIVER' && !_vm.fromEntityViews)?_c('el-upload',{attrs:{"on-change":_vm.uploadGifFile,"action":"","show-file-list":false,"auto-upload":false,"accept":".gif"}},[(_vm.data.filled_by === 'SENDER_OR_RECEIVER' && !_vm.fromEntityViews)?_c('el-button',{attrs:{"size":"small","type":"primary","disabled":_vm.isFromDocument ? false : _vm.checkReadonly() || _vm.readonly()}},[_vm._v(" Upload ")]):_vm._e()],1):_vm._e(),(_vm.form[_vm.data.key] && !_vm.checkReadonly() && !_vm.getLogoUrl)?_c('span',[_c('el-button',{staticStyle:{"background-color":"ivory","max-width":"27px","margin-left":"5px"},attrs:{"type":"plain","size":"mini","circle":""},on:{"click":function($event){return _vm.deleteImage()}}},[_c('img',{attrs:{"width":"12px","src":require("@/assets/Trash.svg")}})])],1):_vm._e()],1)]):_vm._e(),(_vm.isList && _vm.data.properties.filed_content != 'Hide')?_c('div',[_c('img',{style:(_vm.getStyle),attrs:{"src":_vm.getLogoUrlGlobal,"alt":"IMG"}})]):_vm._e()]):_vm._e()]),(_vm.isFromDocument)?_c('div',[(_vm.form[_vm.data.key])?_c('img',{style:(_vm.getStyle),attrs:{"src":_vm.form[_vm.data.key],"alt":"IMG"}}):_c('img',{style:(_vm.getStyle),attrs:{"src":require("../../../../src/assets/img/default_img.jpg"),"alt":"IMG"}}),(
        (_vm.isFromDocument
          ? this.filledByFromDocument[0] === 'RECEIVER'
          : _vm.data.filled_by === 'RECEIVER') && !_vm.fromEntityViews
      )?_c('el-button',{attrs:{"size":"small","type":"primary","disabled":_vm.isDisable},on:{"click":function($event){_vm.outerVisible = true}}},[_vm._v("Upload")]):_vm._e()],1):_vm._e(),_c('dialog-component',{attrs:{"title":'Update picture',"visible":_vm.outerVisible,"width":_vm.getIsMobile ? '100%' : '100%',"height":_vm.getIsMobile ? '100%' : '100%'},on:{"before-close":function($event){_vm.outerVisible=false}}},[_c('vue-anka-cropper',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.upLoadEffect),expression:"upLoadEffect"}],staticClass:"upload-file mt-1 mb-1",attrs:{"options":{
        aspectRatio: false,
        closeOnSave: true,
        cropArea: 'box',
        croppedHeight: 1000,
        croppedWidth: 1000,
        cropperHeight: false,
        dropareaMessage: ' Drag & Drop  file here or use the button below.',
        frameLineDash: [1, 1000],
        frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
        handleFillColor: 'rgba(255, 255, 255, 0.2)',
        handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
        handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
        handleSize: 5,
        handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
        layoutBreakpoint: 40,
        maxCropperHeight: 1000,
        maxFileSize: 8000000,
        overlayFill: 'rgba(0, 0, 0, 0.5)',
        previewOnDrag: true,
        previewQuality: 0.65,
        resultQuality: 0.8,
        resultMimeType: 'image/jpeg',
        resultMimeType: 'image/png',
        resultMimeType: 'image/jpg',
        resultMimeType: 'image/gif',
        selectButtonLabel: 'Select File Supported : JPG, PNG, JPEG, GIF',

        showPreview: true,
        skin: 'light',
        uploadData: {},
        uploadTo: false,
      },"name":"logo","accept":"image/jpeg,image/png,image/jpg,image/gif"},on:{"cropper-error":_vm.fileError,"cropper-file-selected":_vm.insertImage,"cropper-preview":_vm.setImageData,"cropper-saved":_vm.setImageData}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.outerVisible = false;
          _vm.resetImgData();}}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.closeImage}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }